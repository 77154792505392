import React from "react";
import { Container } from "@atoms";
import { Heading, ImageCard } from "@molecules";
import clsx from "clsx";

const ImageGrid = ({ heading, grid, cols, color }) => {
  const calcColor = i => {
    if ((i + 1) % 4 === 0) return "pink";
    if ((i + 1) % 4 === 3) return "teal";
    if ((i + 1) % 4 === 2) return "yellow";
    if ((i + 1) % 4 === 1) return "purple";
    return "purple";
  };
  const calcCorner = i => {
    if ((i + 1) % 4 === 0) return "bottomLeft";
    if ((i + 1) % 4 === 3) return "topRight";
    if ((i + 1) % 4 === 2) return "bottomRight";
    if ((i + 1) % 4 === 1) return "topLeft";
    return "topLeft";
  };
  return (
    <section
      className={clsx({
        "my-16 sm:my-20 lg:my-32": heading,
        "mb-10 sm:mb-20 lg:mb-32": !heading,
      })}
    >
      <Container className="text-center">
        <Heading heading={heading} size="h2" />
        {grid?.length > 0 && (
          <div
            className={clsx(
              "mt-6 grid grid-cols-1",
              { "mt-6": heading },
              { "mt-12": !heading },
              {
                "gap-8 gap-y-16 sm:grid-cols-2 md:gap-4 lg:grid-cols-3":
                  cols === 3,
              },
              { "gap-12 sm:grid-cols-2 sm:gap-6": cols === 2 }
            )}
          >
            {grid?.map((item, i) => {
              return (
                <ImageCard
                  {...item}
                  bgColor={color || calcColor(i)}
                  roundedCorner={item?.roundedCorner || calcCorner(i)}
                />
              );
            })}
          </div>
        )}
      </Container>
    </section>
  );
};

ImageGrid.defaultProps = {
  cols: 3,
};

export default ImageGrid;
